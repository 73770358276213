import axios from "axios";
import dayjs from "dayjs";
import { isOffert } from "./bookingUtils";

const API_URL = "https://backend.putso.nu/api/mail";

export const sendBookingEmail = (
  callback,
  windows,
  howOften,
  windowsPages,
  optional,
  date,
  leftKeyAtOffice,
  firstName,
  lastName,
  email,
  mobileNumber,
  address,
  postNumber,
  ort,
  personNumber,
  notes,
  price
) => {
  axios
    .post(`${API_URL}`, {
      subject: `New Booking!`,
      html: `<div>
				<h2>New Booking for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>How often cleaning: ${howOften}</p>
				<h4>Windows types:</h4>
				${generateWindows(windows)}
				<p>Windows pages: ${windowsPages}-sigit</p>
				<p>Optional: ${optional?.join(", ")}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Left key at office: ${leftKeyAtOffice}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Personnummer: ${personNumber}</p>
				<p>Notes: ${notes}</p>
				<h2>PRICE: ${isOffert(windows, howOften, optional) ? "Offert" : price}</h2>
			</div> `,
    })
    .then((response) => {
      callback();
    })
    .catch((err) => {
      console.log("EMAIL ERR", err?.response?.data);
    });
};

const generateWindows = (windows) => {
  return Object.entries(windows)
    .map(([key, value]) => `<p>Typ: ${key}, antal fönster: ${value}</p>`)
    .join("");
};

export const phoneBookingEmail = (phone, callback) => {
  axios
    .post(`${API_URL}`, {
      subject: `New Phone Booking!`,
      html: `<div>
				<h2>New Phone Booking for phone number: <a href="tel:${phone}">${phone}</a></h2>
			</div> `,
    })
    .then((response) => {
      callback();
    })
    .catch((err) => {
      console.log("PHONE EMAIL BOOKING ERR", err?.response?.data);
    });
};

export const sendJobMail = (
  step1Values,
  step2Values,
  firstName,
  lastName,
  email,
  mobileNumber,
  personNumber,
  notes,
  file,
  callback
) => {
  axios
    .post(`${API_URL}/cv`, {
      subject: `New Job Application!`,
      html: `<div>
			<h2>New Job application</h2>
			${generateKeyValues(step1Values)}
			${generateKeyValues(step2Values)}
			<p>First name: ${firstName}</p>
			<p>Last name: ${lastName}</p>
			<p>Email: ${email}</p>
			<p>Mobile number: ${mobileNumber}</p>
			<p>Personnummer: ${personNumber}</p>
			<p>Notes: ${notes}</p>
		</div> `,
      file: file,
    })
    .then(() => {
      callback();
      window.scrollTo(0, 0);
    });
};

const generateKeyValues = (object) => {
  return Object.entries(object)
    .map(
      ([key, value]) =>
        `<p><span style="font-weight: bold;">${key}</span>: ${value}</p>`
    )
    .join("");
};
