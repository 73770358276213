export const sumOfWindows = (windows) => {
  let sum = 0;
  Object.entries(windows).forEach(([key, value]) => {
    sum += value;
  });

  return sum;
};

export const isOffert = (windows, howOften, optional) => {
  if (windows["other"] > 0) {
    return true;
  }

  if (howOften === "Putso Plus - Ja" || howOften === "Anpassad") {
    return true;
  }

  if (optional.some((el) => el === "Höjdtillägg")) {
    return true;
  }

  return false;
};

export function isValidPhone(phone) {
  const regex = /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/;
  return regex.test(phone);
}
